<!--
 * @Description: 任务单详情---》调度信息
 * @Author: ChenXueLin
 * @Date: 2021-10-13 09:35:43
 * @LastEditTime: 2022-04-21 11:22:48
 * @LastEditors: LiangYiNing
-->
<template>
  <div :element-loading-background="loadingBackground" v-loading="loading">
    <el-table
      :data="schedulInfoList"
      border
      highlight-current-row
      :height="tableHeight"
    >
      <el-table-column
        show-overflow-tooltip
        v-for="(column, index) in columnData"
        :key="index"
        :prop="column.fieldName"
        :label="column.fieldLabel"
        :min-width="column.width"
        :fixed="column.fixed"
        :align="column.align"
        header-align="center"
      >
      </el-table-column>
      <el-table-column fixed="right" align="center" label="操作" width="150">
        <template slot-scope="scope">
          <e6-td-operate
            :data="getOperateList(scope.row)"
            @command="handleOperate($event, scope.row)"
          ></e6-td-operate>
        </template>
      </el-table-column>
    </el-table>
    <!-- 通话记录弹框 -->
    <el-dialog
      v-dialogDrag
      title="通话记录"
      :visible="callRecordsVisible"
      width="1000px"
      :close-on-click-modal="false"
      :element-loading-background="loadingBackground"
      :before-close="
        () => {
          handleClose('callRecordsVisible');
        }
      "
      custom-class="createDispatchDialog"
    >
      <el-table :data="callTable" height="400px">
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in callRecordsColumn"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :fixed="column.fixed"
          :align="column.align"
          header-align="center"
        >
        </el-table-column>
        <el-table-column fixed="right" align="center" label="操作" width="150">
          <template slot-scope="scope">
            <el-button type="text" @click="playTape(scope.row)">
              播放录音
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 查看异常完成原因 -->
    <abnormal-reason
      :reasonVisible="reasonVisible"
      :reasonLabel="reasonLabel"
      :title="dialogTitle"
      :dialogLoading="dialogLoading"
      :reasonForm="reasonForm"
      @handleClose="handleClose"
    ></abnormal-reason>
    <!-- 异常完成输入 -->
    <abnormal-finish-dialog
      :abnormalFinishDialog="abnormalFinishDialog"
      abnormalType="2"
      :scheduleId="scheduleId"
      @handleClose="handleClose"
      @refreshData="getPatchInfoListReq"
    ></abnormal-finish-dialog>
    <!-- 播放录音 -->
    <!-- <play-video
      :videoId="detailInfo.record"
      :dialogVisible="dialogVisible"
      @handleClose="handleClose"
    ></play-video> -->
  </div>
</template>

<script>
import AbnormalFinishDialog from "@/components/workOrderManage/abnormalFinishDialog.vue";
import { getPatchInfoList, taskInfoAbnormal } from "@/api";
import { printError } from "@/utils/util";
import base from "@/mixins/base";
import {
  schedulTasksColumn,
  schedulInfoColumn,
  feeColumn,
  callRecordsColumn
} from "../tableColumnData";
import AbnormalReason from "@/components/workOrderManage/abnormalReason.vue";
// import PlayVideo from "@/components/playVideo.vue";
export default {
  name: "dispatcherInfo",
  // PlayVideo
  components: { AbnormalFinishDialog, AbnormalReason },
  data() {
    return {
      loading: false,
      scheduleId: "", //
      schedulInfoList: [], //调度信息数据
      callRecordsVisible: false, //通话记录弹框
      callTable: [], //通话记录
      callRecordsColumn: callRecordsColumn,
      reasonVisible: false, //异常完成原因
      reasonForm: {
        reason: "",
        remark: ""
      },
      dialogTitle: "查看异常原因",
      reasonLabel: "异常原因",
      /*****异常完成填写弹框*********/
      abnormalFinishDialog: false,
      scheduleInfoId: "",
      dialogLoading: false,
      // 播放录音
      dialogVisible: false,
      curSrc: ""
    };
  },
  props: ["type", "taskId", "tableHeight"],
  mixins: [base],
  computed: {
    columnData() {
      if (this.type == "two") {
        return schedulTasksColumn;
      } else if (this.type == "three") {
        return schedulInfoColumn;
      } else {
        return feeColumn;
      }
    }
  },
  watch: {
    type(val) {
      if (val == "three") {
        //调度信息
        this.schedulInfoList = [];
        this.getPatchInfoListReq();
      }
    }
  },
  created() {},
  methods: {
    //获取调度信息列表
    async getPatchInfoListReq() {
      try {
        this.loading = true;
        let res = await getPatchInfoList({
          taskId: this.taskId
        });
        this.schedulInfoList = res.data;
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //调度信息---查看异常完成原因
    async getTaskInfoAbnormal(scheduleInfoId) {
      try {
        this.dialogLoading = true;
        let res = await taskInfoAbnormal({
          scheduleInfoId
        });
        if (res.code == "OK") {
          this.reasonForm = res.data;
          this.reasonVisible = true;
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    // 获取页面操作权限
    getOperateList(row) {
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1
        }
      ];
      if (row.scheduleInfoStatusCode == 6) {
        operates.push({
          label: "查看异常完成原因",
          id: 2
        });
      }
      let status = [5, 6, 7, 8];
      // row.scheduleInfoStatusCode != 5 &&
      // row.scheduleInfoStatusCode != 6 &&
      // row.scheduleInfoStatusCode != 7 &&
      // row.scheduleInfoStatusCode != 8
      if (
        status.findIndex(item => {
          return row.scheduleInfoStatusCode == item;
        }) == -1
      ) {
        operates.push({
          label: "异常完成",
          id: 3
        });
      }
      if (row.scheduleInfoStatusCode == 7) {
        operates.push({
          label: "查看拒绝原因",
          id: 4
        });
      }
      return operates;
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        //详情
        this.routerPush({
          name: "taskListManage/schedulingInfoDetails",
          params: {
            refresh: true,
            scheduleInfoId: row.scheduleInfoId,
            taskId: this.taskId
          }
        });
      }
      //查看异常原因
      if (val.id == 2) {
        this.reasonVisible = true;
        this.dialogTitle = "查看异常原因";
        this.reasonLabel = "异常原因";
        this.getTaskInfoAbnormal(row.scheduleInfoId);
      }
      //异常完成
      if (val.id == 3) {
        this.abnormalFinishDialog = true;
        this.scheduleId = row.scheduleInfoId;
      }
      if (val.id == 4) {
        //查看拒绝原因
        this.dialogTitle = "查看拒绝原因";
        this.reasonLabel = "拒绝原因";
        this.reasonVisible = true;
        this.getTaskInfoAbnormal(row.scheduleInfoId);
      }
    },
    //播放录音
    playTape(row) {
      this.dialogVisible = true;
      this.curSrc = row.record;
    },
    handleClose(name) {
      this[name] = false;
      this.$emit("refreshData");
    }
  }
};
</script>
<style lang="scss" scoped></style>
