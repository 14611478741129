<!--
 * @Description: 任务单详情---》调度任务
 * @Author: ChenXueLin
 * @Date: 2021-10-13 09:35:43
 * @LastEditTime: 2022-09-21 17:12:37
 * @LastEditors: ChenXueLin
-->
<template>
  <div :element-loading-background="loadingBackground" v-loading="loading">
    <el-table
      :data="schedulTasksList"
      border
      highlight-current-row
      :height="tableHeight"
    >
      <el-table-column
        show-overflow-tooltip
        v-for="(column, index) in columnData"
        :key="index"
        :prop="column.fieldName"
        :label="column.fieldLabel"
        :min-width="column.width"
        :fixed="column.fixed"
        :align="column.align"
        header-align="center"
      >
        <template slot-scope="{ row }">
          <div
            class="table-label"
            v-if="column.fieldName === 'tag' && row[column.fieldName]"
          >
            <span
              class="label-box"
              v-for="(item, index) in row.tag"
              :key="index"
            >
              {{ item }}
            </span>
          </div>
          <span v-else>{{ row[column.fieldName] }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" align="center" label="操作" width="150">
        <template slot-scope="scope">
          <e6-td-operate
            :data="getOperateList(scope.row)"
            @command="handleOperate($event, scope.row)"
          ></e6-td-operate>
        </template>
      </el-table-column>
    </el-table>
    <!-- 通话记录弹框 -->
    <el-dialog
      v-dialogDrag
      title="通话记录"
      :visible="callRecordsVisible"
      width="1000px"
      :close-on-click-modal="false"
      :element-loading-background="loadingBackground"
      :before-close="
        () => {
          handleClose('callRecordsVisible');
        }
      "
      custom-class="createDispatchDialog"
    >
      <el-table :data="callTable" height="400px">
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in callRecordsColumn"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :fixed="column.fixed"
          :align="column.align"
          header-align="center"
        >
        </el-table-column>
        <el-table-column fixed="right" align="center" label="操作" width="150">
          <template slot-scope="scope">
            <el-button type="text" @click="playTape(scope.row)">
              播放录音
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 查看异常完成原因 -->
    <abnormal-reason
      :reasonVisible="reasonVisible"
      :reasonLabel="reasonLabel"
      :title="dialogTitle"
      :dialogLoading="dialogLoading"
      @handleClose="handleClose"
      :reasonForm="reasonForm"
    ></abnormal-reason>
    <!-- 异常完成输入 -->
    <abnormal-finish-dialog
      :abnormalFinishDialog="abnormalFinishDialog"
      abnormalType="1"
      :scheduleId="scheduleId"
      @handleClose="handleClose"
      @refreshData="refreshData"
    ></abnormal-finish-dialog>
    <!-- 播放录音 -->
    <play-video
      :recordUrl="recordUrl"
      :dialogVisible="dialogVisible"
      @handleClose="handleClose"
    ></play-video>
  </div>
</template>

<script>
import AbnormalFinishDialog from "@/components/workOrderManage/abnormalFinishDialog.vue";
import { getPatchTaskList, urgent, taskAbnormal, getHistoryCall } from "@/api";
import { printError } from "@/utils/util";
import base from "@/mixins/base";
import { schedulTasksColumn, callRecordsColumn } from "../tableColumnData";
import AbnormalReason from "@/components/workOrderManage/abnormalReason.vue";
import PlayVideo from "@/components/playVideo.vue";
export default {
  name: "dispatcherTask",
  components: { AbnormalFinishDialog, AbnormalReason, PlayVideo },
  data() {
    return {
      loading: false,
      dialogLoading: false,
      schedulTasksList: [], // 调度任务数据
      columnData: schedulTasksColumn,
      callRecordsVisible: false, //通话记录弹框
      callTable: [], //通话记录
      recordUrl: "", //录音地址
      callRecordsColumn: callRecordsColumn,
      reasonVisible: false, //异常完成原因
      reasonForm: {
        reason: "",
        remark: ""
      },
      dialogTitle: "查看异常原因",
      reasonLabel: "异常原因",
      /*****异常完成填写弹框*********/
      abnormalFinishDialog: false,
      scheduleId: "",
      dialogVisible: false
    };
  },
  props: ["type", "taskId", "tableHeight"],
  mixins: [base],
  computed: {},
  watch: {
    type(val) {
      if (val == "two") {
        //调度任务
        this.schedulTasksList = [];
        this.getPatchTaskListReq();
      }
    }
  },
  created() {},
  methods: {
    //获取调度任务列表
    async getPatchTaskListReq() {
      try {
        this.loading = true;
        let res = await getPatchTaskList({
          taskId: this.taskId
        });
        //基本信息
        this.schedulTasksList = res.data;
        this.schedulTasksList.map(item => {
          item.tag = item.tag ? item.tag.split(",") : [];
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //调度任务加急、取消加急
    async handleUrgent(patchTaskId) {
      try {
        this.loading = true;
        let res = await urgent({
          taskId: this.taskId,
          patchTaskId
        });
        if (res.code == "OK") {
          this.$message.success("操作成功");
          this.getPatchTaskListReq();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //调度任务---查看异常完成原因
    async getTaskAbnormal(scheduleId) {
      try {
        this.dialogLoading = true;
        let res = await taskAbnormal({
          scheduleId,
          type: 1
        });
        if (res.code == "OK") {
          this.reasonForm = res.data;
          this.reasonVisible = true;
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    // 获取页面操作权限
    getOperateList(row) {
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1
        },
        {
          label: "通话记录",
          id: 4
        }
      ];
      if (row.isUrgented) {
        //1 已加急 0 未加急
        operates.push({
          label: "取消加急",
          id: 2
        });
      } else {
        operates.push({
          label: "加急",
          id: 2
        });
      }
      if (row.scheduleTaskStatusCode == 4) {
        //4 已异常完成 0 未异常完成
        operates.push({
          label: "查看异常完成原因",
          id: 5
        });
      }
      if (row.scheduleTaskStatusCode != 4 && row.scheduleTaskStatusCode != 3) {
        operates.push({
          label: "异常完成",
          id: 3
        });
      }
      return operates;
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        //详情
        this.routerPush({
          name: "taskListManage/schedulingTaskDetails",
          params: {
            refresh: true,
            scheduleId: row.scheduleTaskId
          }
        });
      }
      if (val.id == 2) {
        //取消加急/加急
        this.handleUrgent(row.scheduleTaskId);
      }
      if (val.id == 3) {
        //异常完成
        this.abnormalFinishDialog = true;
        this.scheduleId = row.scheduleTaskId;
      }
      if (val.id == 4) {
        this.callRecordsVisible = true;
        this.getCallRecords(row.scheduleTaskId);
      }
      if (val.id == 5) {
        //查看异常完成原因
        this.reasonVisible = true;
        this.dialogTitle = "查看异常原因";
        this.reasonLabel = "异常原因";
        this.getTaskAbnormal(row.scheduleTaskId);
      }
    },
    //获取通话记录
    async getCallRecords(sourceId) {
      try {
        this.dialogLoading = true;
        let res = await getHistoryCall({
          sourceId,
          sourceType: 1
        });
        if (res.code == "OK") {
          this.callTable = res.data;
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //播放录音
    playTape(row) {
      this.dialogVisible = true;
      this.recordUrl = row.record;
    },
    refreshData() {
      this.$emit("refreshData");
      this.getPatchTaskListReq();
    },
    handleClose(name) {
      this[name] = false;
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.createDispatchDialog {
  .el-dialog__body {
    padding: 20px;
  }
}
.table-label {
  .label-box {
    padding: 0px 10px;
    border-radius: 20px;
    display: inline-block;
    border: 1px solid #46bfea;
    color: #46bfea;
    margin: 5px;
  }
}
</style>
