import { render, staticRenderFns } from "./playVideo.vue?vue&type=template&id=1912d865&scoped=true"
import script from "./playVideo.vue?vue&type=script&lang=js"
export * from "./playVideo.vue?vue&type=script&lang=js"
import style0 from "./playVideo.vue?vue&type=style&index=0&id=1912d865&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1912d865",
  null
  
)

export default component.exports