<!--
 * @Description: 
 * @Author: ChenXueLin
 * @Date: 2022-01-06 15:01:00
 * @LastEditTime: 2022-07-01 09:21:23
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    title="录音播放"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    custom-class="video-dialog"
  >
    <template>
      <audio :src="src" ref="audio" autoplay="" controls=""></audio>
    </template>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import { getVideoSrc } from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "playVideo",
  components: {},
  data() {
    return {
      src: ""
    };
  },
  props: ["dialogVisible", "videoId", "recordUrl"],
  mixins: [base],
  computed: {},
  watch: {
    videoId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getSrc();
        }
      }
    },
    recordUrl: {
      immediate: true,
      handler(val) {
        if (val) {
          this.src = val;
        }
      }
    }
  },
  methods: {
    async getSrc() {
      try {
        this.loading = true;
        let res = await getVideoSrc({ mainUniqueId: this.videoId });
        this.src = res.data.record;
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //音频暂停
    stop() {
      this.$refs.audio.pause();
      this.$refs.audio.currentTime = 0;
    },
    handleClose() {
      this.stop();
      this.$emit("handleClose", "dialogVisible");
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
/deep/.video-dialog {
  .el-dialog__body {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
