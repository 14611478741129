<!--
 * @Description: 任务单详情---》基本信息页面
 * @Author: ChenXueLin
 * @Date: 2021-10-13 14:42:40
 * @LastEditTime: 2022-09-14 16:33:23
 * @LastEditors: ChenXueLin
-->
<!-- 基本信息 -->
<template>
  <div>
    <!-- 基本信息 start -->
    <div class="baseInfo-box">
      <div class="edit-title" style="margin-top:10px;">
        <div>基本信息</div>
        <i
          style="cursor: pointer;"
          class="e6-icon-arrow-b_line"
          title="展开"
          v-if="!isFold"
          @click="handleFold(true)"
        ></i>
        <i
          style="cursor: pointer;"
          class="e6-icon-arrow-t_line"
          title="收起"
          v-else
          @click="handleFold(false)"
        ></i>
      </div>
      <el-form label-width="100px" class="editForm">
        <el-row>
          <el-col :span="8">
            <el-form-item label="工单来源">
              {{ basicInfo.taskSourceName }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="任务类型">
              {{ basicInfo.taskFirstClassName }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="工单类型标签">
              {{ basicInfo.workTypeTagName }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="任务单号">
              {{ basicInfo.taskNo }}
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-form label-width="100px" label-position="right">
        <el-row>
          <el-col :span="8" v-if="isFold">
            <el-form-item label="创建人">
              {{ basicInfo.createdTBy }}
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="isFold">
            <el-form-item label="创建时间">
              {{ basicInfo.createdTime }}
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="basicInfo.taskTypeCode == 12 && isFold">
            <el-form-item label="是否需要报停">
              {{ basicInfo.isStop }}
            </el-form-item>
          </el-col>
          <el-col
            :span="8"
            v-if="basicInfo.taskTypeCode == 1 || basicInfo.taskTypeCode == 2"
          >
            <el-form-item label="计费方式">
              {{ basicInfo.billingMethod }}
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="isFold">
            <el-form-item label="业务员">
              {{ basicInfo.salesman }}
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="isFold">
            <el-form-item label="业务员电话">
              {{ basicInfo.salesmanPhone }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="签约客户名称">
              {{ basicInfo.signCustomerName }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="现运营">
              {{ basicInfo.nowOperationName }}
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="basicInfo.taskTypeCode == 12 && isFold">
            <el-form-item label="是否回收设备">
              {{ basicInfo.isRecovery }}
            </el-form-item>
          </el-col>
          <el-col
            :span="8"
            v-if="basicInfo.taskTypeCode == 1 || basicInfo.taskTypeCode == 2"
          >
            <el-form-item label="业务类型">
              {{ basicInfo.businessType }}
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- :class="isFold ? '' : 'editForm'" -->
      <el-form label-width="100px" class="mbForm">
        <el-row>
          <el-col :span="8">
            <el-form-item label="送货方式">
              {{ basicInfo.isDeliverInstallName }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="客户名称">
              {{ basicInfo.customerName }}
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="basicInfo.corpContactName">
            <el-form-item label="客户联系人">
              {{ basicInfo.corpContactName }}
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="basicInfo.corpContactPhone">
            <el-form-item label="客户联系电话">
              {{ basicInfo.corpContactPhone }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="实施联系人">
              {{ basicInfo.contact }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="实施联系电话">
              {{ basicInfo.contactPhone }}
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="basicInfo.taskType != 3">
            <el-form-item label="联系地址">
              {{ basicInfo.address }}
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="basicInfo.taskType == 3">
            <el-form-item label="业务员">
              {{ basicInfo.salesman }}
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="basicInfo.taskType == 3">
            <el-form-item label="业务员电话">
              {{ basicInfo.salesmanPhone }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="期望上门时间">
              {{ basicInfo.expactTimeStr }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="下单人">
              {{ basicInfo.auctionOrderUser }}
            </el-form-item>
          </el-col>
          <!-- <el-col :span="24">
            <el-form-item label="任务详情">
              {{ basicInfo.taskDetail }}
            </el-form-item>
          </el-col> -->
          <el-col :span="24">
            <el-form-item label="实施原因">
              {{ basicInfo.taskDetail ? basicInfo.taskDetail + "/" : ""
              }}{{ basicInfo.remark }}</el-form-item
            >
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!-- 基本信息 end -->
    <!-- 新装/加装任务项 -->
    <new-add
      :customerId="basicInfo.customerId"
      :taskId="taskId"
      pageType="newAdd"
      :basicInfo="basicInfo"
      ref="itemChild"
      v-if="[1, 2, 7, 19, 20].includes(basicInfo.taskTypeCode)"
    ></new-add>
    <!-- 维修、重装、拆卸任务项列表 -->
    <maintain
      :customerId="basicInfo.customerId"
      :taskType="basicInfo.taskTypeCode"
      :taskId="taskId"
      :basicInfo="basicInfo"
      ref="itemChild"
      v-if="
        basicInfo.taskTypeCode == 10 ||
          basicInfo.taskTypeCode == 3 ||
          basicInfo.taskTypeCode == 12
      "
    ></maintain>
    <!-- 巡检任务项列表 -->
    <check
      :customerId="basicInfo.customerId"
      :taskId="taskId"
      ref="itemChild"
      :basicInfo="basicInfo"
      v-if="basicInfo.taskTypeCode == 11"
    ></check>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import NewAdd from "./tableComponent/newAdd.vue";
import Maintain from "./tableComponent/maintain.vue";
import Check from "./tableComponent/check.vue";
export default {
  name: "",
  data() {
    return {
      //taskType: 1, //1新装2加装,3重装4开通5报停7以新换旧8套餐变更9转网10维修11巡检12拆卸
      recordsList: [1, 2],
      isFold: false //是否展开
    };
  },
  props: ["basicInfo", "taskId"],
  mixins: [base, listPage, listPageReszie],
  computed: {},
  components: {
    NewAdd,
    Maintain,
    Check
  },
  watch: {},
  methods: {
    handleFold(isFold) {
      this.isFold = isFold;
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
@import "../../../../../../assets/styles/index.scss";
.baseInfo-content-wrap {
  display: flex;
  width: 100%;
  // height: 100%;
  position: relative;
  .detailInfo-content {
    width: calc(100% - 300px);
    height: 100%;
    border-right: 1px solid #f0f0f0;
    box-sizing: border-box;
    overflow-y: auto;
  }

  .task-list-content {
    .table-title-btn {
      height: 50px;
      padding-left: 15px;
      padding-right: 15px;
      line-height: 50px;
      font-size: 14px;
      box-sizing: border-box;
      color: #303133;
      border-width: 1px 0;
      border-style: solid;
      border-color: #f5f7fa;
      background-color: #f9fafc;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      i {
        margin-left: 10px;
        height: 28px;
        margin-right: 10px;
        color: $--color-primary;
        font-size: 12px;
        line-height: 28px;
        font-style: normal;
        background-color: $--color-primary-light-8; // #daf2fb
        text-align: center;
        padding: 0 10px;
        cursor: pointer;
        border-radius: 3px;
      }
      [class^="e6-icon"] {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 28px;
        padding: 0;
        font-size: 16px;
      }
    }
    .search-list {
      background: #fff !important;
    }
    .label-button-box {
      width: 100%;
      padding: 5px 10px;
      box-sizing: border-box;
    }
  }
  .record-box {
    width: 300px;
    height: 100%;
    padding: 10px;
    // max-height: 550px;
    overflow-y: auto;
    box-sizing: border-box;
    .switch-btn {
      display: flex;
      & > div {
        color: #6d7075;
      }
      & > div:nth-child(1) {
        margin-right: 30px;
      }
      .active {
        color: #48c0e5;
        font-weight: 600;
        &:after {
          content: "";
          display: block;
          width: 65px;
          height: 3px;
          background: #48c0e5;
          margin-top: 10px;
        }
      }
    }
  }
}
/deep/ .baseInfo-box {
  .el-form {
    .el-form-item {
      margin-bottom: 5px;
    }
  }
}
</style>
