<!--
 * @Description: 已安装设备弹框
 * @Author: ChenXueLin
 * @Date: 2021-10-14 17:47:42
 * @LastEditTime: 2022-07-01 09:22:23
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    title="查看已安装设备"
    :visible.sync="showEquipVisible"
    width="1000px"
    :before-close="handleClose"
    :close-on-click-modal="false"
    v-loading="loading"
    :element-loading-background="loadingBackground"
    custom-class="edit-customer-dialog"
  >
    <el-form class="dialog-form">
      <el-row>
        <el-col :span="8">
          <el-form-item label="场景名称">{{ sceneName }}</el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="安装场景">{{ sceneTypeName }}</el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table border height="300px" :data="equipmentData" highlight-current-row>
      <el-table-column
        show-overflow-tooltip
        v-for="(column, index) in columnData"
        :key="index"
        :prop="column.fieldName"
        :label="column.fieldLabel"
        :min-width="column.width"
        :fixed="column.fixed"
        :align="column.align"
        header-align="center"
      >
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { getListInstalled } from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "",
  props: ["showEquipVisible", "sceneName", "sceneTypeName", "vehicleId"],
  components: {},
  data() {
    return {
      loading: false,
      columnData: [
        {
          fieldName: "thirdClassId",
          display: true,
          fieldLabel: "商品ID",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "thirdClassName",
          display: true,
          fieldLabel: "商品名称",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "firstClassName",
          display: true,
          fieldLabel: "商品分类",
          width: 180,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "equipCode",
          display: true,
          fieldLabel: "设备编号",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "realNo",
          display: true,
          fieldLabel: "SIM卡号",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "commids",
          display: true,
          fieldLabel: "中心识别码",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ],
      equipmentData: []
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {
    showEquipVisible: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getListInstalled();
        }
      }
    }
  },
  methods: {
    //查询已安装设备
    async getListInstalled() {
      try {
        let res = await getListInstalled({
          id: this.vehicleId
        });
        this.equipmentData = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    handleClose() {
      this.$emit("handleClose");
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
.dialog-form {
  margin-top: 20px;
  margin-left: 20px;
}
</style>
