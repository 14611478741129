<template>
  <!-- 超期原因 -->
  <el-dialog
    title="超期原因"
    :visible.sync="overdueReasonVisible"
    :close-on-click-modal="false"
    width="500px"
    v-loading="dialogLoading"
    :element-loading-background="loadingBackground"
    custom-class="custom-dialog"
    :before-close="closeDialog"
    :modal-append-to-body="false"
    @open="openDialog"
  >
    <el-form
      :model="overdueReasonForm"
      label-width="200px"
      ref="overdueReasonForm"
      :rules="rules"
    >
      <!-- :rules="formRule" -->
      <el-form-item label="一级超期原因:" label-width="60" prop="firstReason">
        <e6-vr-select
          v-model="overdueReasonForm.firstReason"
          :data="firstReasonList"
          @change="changeReason"
          :props="{
            id: 'codeValue',
            label: 'codeName'
          }"
          placeholder="一级超期原因"
          title="一级超期原因"
          clearable
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="二级超期原因" label-width="60" prop="overdueReason">
        <e6-vr-select
          v-model="overdueReasonForm.overdueReason"
          :data="secondReasonList"
          :props="{
            id: 'codeValue',
            label: 'codeName'
          }"
          placeholder="二级超期原因"
          title="二级超期原因"
          clearable
        ></e6-vr-select>
      </el-form-item>
    </el-form>
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="closeDialog">取消</el-button>
      <el-button type="primary" @click="handleSubmit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { findDownList, getOverdueReason } from "@/api";
import base from "@/mixins/base";
import { printError } from "@/utils/util";
export default {
  name: "overdueReasonDialog",
  components: {},
  data() {
    return {
      dialogLoading: false,
      overdueReasonForm: {
        firstReason: "",
        overdueReason: ""
      },
      firstReasonList: [],
      secondReasonList: [],
      rules: {
        firstReason: [
          {
            required: true,
            message: "请选择一级超期原因",
            trigger: ["blur", "change"]
          }
        ],
        overdueReason: [
          {
            required: true,
            message: "请选择二级超期原因",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  mixins: [base],
  props: ["itemIdList", "overdueReasonVisible"],
  computed: {},
  watch: {},
  methods: {
    openDialog() {
      this.getSelectList("overdueReason", 1);
      this.$nextTick(() => {
        this.$refs.overdueReasonForm.clearValidate();
      });
    },

    async getSelectList(key, type) {
      try {
        let res = await findDownList([key]);
        if (type == 1) {
          this.firstReasonList = res.data[key];
        } else {
          this.secondReasonList = res.data[key];
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    changeReason(val, node) {
      this.getSelectList(node.codeValue, 2);
    },
    handleSubmit() {
      this.$refs.overdueReasonForm.validate(valid => {
        if (valid) {
          this.submitReq();
        }
      });
    },
    async submitReq() {
      try {
        let res = await getOverdueReason({
          overdueReason: this.overdueReasonForm.overdueReason,
          itemIdList: this.itemIdList
        });
        if (res.code == "OK") {
          this.$message.success("操作成功");
          this.closeDialog();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    closeDialog() {
      this.overdueReasonForm = {
        firstReason: "",
        overdueReason: ""
      };
      this.$emit("close");
    }
  },
  created() {},
  mounted() {}
};
</script>
<style scoped lang="scss">
/deep/.custom-dialog {
  .el-dialog__body {
    padding: 15px 20px;
    box-sizing: border-box;

    .el-form {
      margin-top: 15px;
      .el-textarea {
        width: 350px;
      }
    }
  }
}
</style>
