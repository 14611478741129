<!--
 * @Description: 任务单详情---》出库信息
 * @Author: ChenXueLin
 * @Date: 2021-10-13 09:35:43
 * @LastEditTime: 2022-09-21 17:12:37
 * @LastEditors: ChenXueLin
-->
<template>
  <div :element-loading-background="loadingBackground" v-loading="loading">
    <el-table
      :data="tableData"
      border
      highlight-current-row
      :height="tableHeight"
      row-key="outWarehouseDetId"
    >
      <el-table-column
        show-overflow-tooltip
        v-for="(column, index) in columnData"
        :key="index"
        :prop="column.fieldName"
        :label="column.fieldLabel"
        :min-width="column.width"
        :fixed="column.fixed"
        :align="column.align"
        header-align="center"
      >
        <template slot-scope="{ row }">
          <div v-if="column.fieldName === 'expressNo'">
            <el-button type="text" @click="showExpress(row)">
              {{ row.expressNo }}
            </el-button>
          </div>
          <span v-else>{{ row[column.fieldName] }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 物流信息 -->
    <logistics-information
      :logisticsVisible.sync="logisticsVisible"
      :outWarehouseId="expressId"
    ></logistics-information>
  </div>
</template>

<script>
import { getOutWarehouseInfo } from "@/api";
import LogisticsInformation from "./logisticsInformation.vue";
import { printError } from "@/utils/util";
import base from "@/mixins/base";
export default {
  name: "outboundInfo",
  components: { LogisticsInformation },
  data() {
    return {
      loading: false,
      tableData: [],
      columnData: [
        {
          display: true,
          fieldName: "sourceDocumentNo",
          fieldLabel: "来源单号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "applyStatusName",
          fieldLabel: "来源单出库状态",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "warehouseOutNo",
          fieldLabel: "出库单号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "outTime",
          fieldLabel: "出库时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "signStatusName",
          fieldLabel: "签收状态",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "materialNo",
          fieldLabel: "物料编码",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "materialName",
          fieldLabel: "物料名称",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "amount",
          fieldLabel: "出库数量",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "outUserName",
          fieldLabel: "出库人",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "expressCompanyName",
          fieldLabel: "快递公司",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "expressNo",
          fieldLabel: "快递单号",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          display: true,
          fieldName: "signTime",
          fieldLabel: "签收时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ],
      logisticsVisible: false,
      expressId: ""
    };
  },
  props: ["outApplyNo", "type", "tableHeight"],
  mixins: [base],
  computed: {},
  watch: {
    type(val) {
      if (val == "six") {
        this.tableData = [];
        this.getOutWarehouseInfoReq();
      }
    }
  },
  created() {},
  methods: {
    //获取调度任务列表
    async getOutWarehouseInfoReq() {
      try {
        this.loading = true;
        let res = await getOutWarehouseInfo({
          outApplyNos: this.outApplyNo
        });
        this.tableData = this.formatList(res.data.array);
        console.log(this.tableData, "tableData==");
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //格式化tableData
    formatList(data) {
      data.map(item => {
        item.expressCompanyName =
          !item.expressNo && item.signStatus == 2
            ? "自提"
            : item.expressCompanyName;
        if (item.details.length) {
          item.children = item.details.filter((item, idx) => idx != 0);
          item.materialNo = item.details[0].materialNo;
          item.outWarehouseDetId = item.details[0].outWarehouseId;
          item.materialName = item.details[0].materialName;
          item.amount = item.details[0].amount;
          item.outWarehouseDetId = item.details[0].outWarehouseId;
        } else {
          item.children = [];
        }
      });
      return data;
    },
    //点击快递单号
    showExpress(row) {
      console.log(row, "===");
      this.logisticsVisible = true;
      this.expressId = row.outWarehouseId;
    }
  }
};
</script>
<style lang="scss" scoped></style>
