<!--
 * @Description: 巡检任务项列表
 * @Author: ChenXueLin
 * @Date: 2021-11-18 16:08:42
 * @LastEditTime: 2023-03-20 17:20:14
 * @LastEditors: ChenXueLin
-->
<template>
  <!-- 任务项 start -->
  <div class="task-list-content">
    <!-- 巡检点 -->
    <div class="baseInfo-box">
      <div class="edit-title" style="height:50px;">
        <div>巡检点</div>
        <el-button
          type="primary"
          @click="addDispatch"
          v-has="UNDEFINED_5_BUTTON_ID"
          >创建调度任务</el-button
        >
      </div>
      <el-table
        :data="checkData"
        :row-key="rowKey"
        style="width: 100%"
        @selection-change="handleSelection"
        ref="elTable"
        @select-all="selectAll"
      >
        <el-table-column
          type="selection"
          :reserve-selection="true"
          width="50"
          fixed
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in checkColumn"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :fixed="column.fixed"
          :align="column.align"
          header-align="center"
        >
        </el-table-column>
      </el-table>
    </div>
    <!-- 任务项列表 -->
    <template>
      <detail-table-title>
        <template v-slot:title>
          任务项
        </template>
        <template v-slot:btns> </template>
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="itemIdList">
              <input-plus
                v-model="searchForm.itemIdList"
                valueType="Array"
                placeholder="工单ID"
                title="工单ID"
                label="工单ID"
                clearable
              ></input-plus>
            </el-form-item>
            <el-form-item class="search-item--1" prop="sceneName">
              <el-input
                placeholder="场景名称"
                title="场景名称"
                v-model="searchForm.sceneName"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="sceneType">
              <e6-vr-select
                v-model="searchForm.sceneType"
                :data="sceneTypeList"
                placeholder="场景类型"
                title="场景类型"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="engineerrpName">
              <el-input
                placeholder="工程师"
                title="工程师"
                v-model="searchForm.engineerrpName"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="engineerrpPhone">
              <el-input
                placeholder="工程师电话"
                title="工程师电话"
                v-model="searchForm.engineerrpPhone"
              ></el-input>
            </el-form-item>

            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
      </detail-table-title>
      <div class="switch-page-button">
        <el-button
          round
          :class="[clickIndex == index ? 'activeButton' : '']"
          v-for="(btn, index) in btnList"
          :key="index"
          @click="switchPage(index, btn.statusNameCode)"
          >{{ btn.statusName }}{{ btn.count }}</el-button
        >
      </div>
      <el-table
        :data="tableData"
        style="width: 100%"
        height="300px"
        header-row-class-name="table-header"
        @expand-change="rowExpand"
        :row-key="getRowKeys"
        :expand-row-keys="expands"
        @selection-change="handleSelectionChange"
        ref="multipleTable"
      >
        <el-table-column
          type="selection"
          :reserve-selection="true"
          width="50"
        ></el-table-column>
        <!-- 展开行内容 -->
        <el-table-column type="expand">
          <!--  eslint-disable-next-line -->
          <template slot-scope="scope">
            <el-table
              v-loading="childListLoading"
              :data="childTableData"
              border
              style="width: 900px"
            >
              <el-table-column
                show-overflow-tooltip
                v-for="(column, index) in expandedColumn"
                :key="index"
                :prop="column.fieldName"
                :label="column.fieldLabel"
                :min-width="column.width"
                :fixed="column.fixed"
                :align="column.align"
                header-align="center"
              >
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in repairColumn"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :fixed="column.fixed"
          :align="column.align"
          header-align="center"
        >
        </el-table-column>
        <el-table-column fixed="right" align="center" label="操作" width="180">
          <template slot-scope="scope">
            <e6-td-operate
              :data="getOperateList(scope.row)"
              @command="handleOperate($event, scope.row)"
            ></e6-td-operate>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </template>
    <!-- 查看已安装设备弹框 -->
    <installed-devices
      :showEquipVisible="showEquipVisible"
      @handleClose="closeDialog"
      :sceneName="sceneName"
      :sceneTypeName="sceneTypeName"
      :vehicleId="vehicleId"
    ></installed-devices>
    <!-- 创建调度任务弹框 -->
    <el-dialog
      v-dialogDrag
      title="创建调度任务"
      :visible="createDispatchDialog"
      width="500px"
      :close-on-click-modal="false"
      :element-loading-background="loadingBackground"
      :before-close="closeDialog"
      custom-class="createDispatchDialog"
    >
      <!-- <div class="dialog-title">
        <span>已选中7个安装任务</span>
        <span>(忽略8个不可操作任务项)</span>
      </div> -->
      <el-form
        label-width="120px"
        ref="dispatchForm"
        :model="dispatchForm"
        :rules="dispatchFormRules"
      >
        <el-form-item label="预约上门时间" prop="taskArrangedate">
          <el-date-picker
            v-model="dispatchForm.taskArrangedate"
            type="datetime"
            placeholder="选择日期"
            value-format="timestamp"
            popper-class="special"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="备注" prop="remark">
          <el-input
            placeholder="备注"
            v-model="dispatchForm.remark"
            maxlength="100"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="handleSubmit"> 确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import taskDetail from "@/mixins/taskDetail";
import setting from "@/setting";
import inputPlus from "@/components/inputPlus";
import {
  checkAddressList,
  checkTaskList,
  addCheckPatchTask,
  getListLeader,
  findDownList
} from "@/api";
import { printError } from "@/utils/util";
import DetailTableTitle from "@/components/detailTableTitle.vue";
import { checkColumn, repairColumn } from "../../tableColumnData";
import InstalledDevices from "@/components/workOrderManage/installedDevices.vue";
let { UNDEFINED_5_BUTTON_ID } = setting;
export default {
  name: "check",
  components: {
    DetailTableTitle,
    InstalledDevices,
    inputPlus
  },
  data() {
    return {
      UNDEFINED_5_BUTTON_ID,
      repairColumn: repairColumn,
      checkColumn: checkColumn,
      sceneTypeList: [], //场景类型
      checkData: [], //巡检点列表数据
      tableData: [], //任务项列表数据
      searchForm: {
        itemIdList: [], //工单ID
        itemStatus: "", //任务项状态
        sceneName: "", //场景名称
        sceneType: "", //场景类型
        engineerrpName: "", //工程师名称
        engineerrpPhone: "", //工程师电话
        pageIndex: 1,
        pageSize: 20
      },
      total: 0,
      showEquipVisible: false, //是否查看已安装设备
      /*******创建调度任务************/
      createDispatchDialog: false,
      idList: [], //选中的巡检点数据
      engineerList: [], //工程组长下拉框
      dispatchForm: {
        taskArrangedate: "", //预约上门时间
        remark: ""
      },
      dispatchFormRules: {
        taskArrangedate: [
          {
            required: true,
            message: "请选择预约完成时间",
            trigger: ["blur", "change"]
          }
        ]
      },
      vehicleId: "",
      sceneName: "",
      sceneTypeName: ""
    };
  },
  //任务单ID  //客户ID
  props: ["taskId", "customerId", "basicInfo"],
  mixins: [taskDetail],
  computed: {},
  watch: {},
  methods: {
    updateItem() {
      this.getCheckAddressList();
      this.getActionItem();
    },
    // 初始化数据(获取表头,表格数据)
    async initData() {
      try {
        this.selectLoading = true;
        let promiseList = [findDownList(["sceneType"])];
        let [taskTypeRes] = await Promise.all(promiseList);
        //场景类型下拉框
        this.sceneTypeList = this.getFreezeResponse(taskTypeRes, {
          path: "data.sceneType"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    rowKey(row) {
      return row.inspectionId;
    },
    getRowKey(row) {
      return row.itemId;
    },
    // 页码改变
    handleCurrentChange(curPage) {
      this.searchForm.pageIndex = curPage;
      this.getRepairActionItem();
      this.scrollTop = 0;
    },
    // 搜索
    handleSearch() {
      this.searchForm.pageIndex = 1;
      this.getRepairActionItem();
    },
    //获取巡检点列表
    async getCheckAddressList() {
      try {
        let res = await checkAddressList({
          taskId: this.taskId
        });
        //基本信息
        this.checkData = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //点击筛选框
    switchPage(index, itemStatus) {
      this.clickIndex = index;
      this.searchForm.itemStatus = itemStatus;
      this.getActionItem();
      this.itemIdList = [];
      this.itemList = [];
      this.$refs.multipleTable.clearSelection();
    },
    //获取巡检任务单任务项列表
    async getActionItem() {
      try {
        let res = await checkTaskList({
          taskId: this.taskId,
          ...this.searchForm
        });

        this.tableData = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "任务项详情",
          id: 1
        },
        {
          label: "查看已安装设备",
          id: 2
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.routerPush({
          name: "taskListManage/ActionItemDetail",
          params: {
            refresh: true,
            itemId: row.itemId
          }
        });
      } else {
        this.showEquipVisible = true;
        this.vehicleId = row.vehicleId;
        this.sceneName = row.sceneName;
        this.sceneTypeName = row.sceneType;
      }
    },
    /*****创建调度任务********/
    // 勾选任务项
    handleSelection(columns) {
      this.idList = columns.map(item => {
        return item.inspectionId;
      });
    },
    //点击创建调度任务
    addDispatch() {
      if (!this.idList.length) {
        this.$message.warning("请先勾选数据");
        return;
      }
      this.createDispatchDialog = true;
      this.dispatchForm.taskArrangedate = this.basicInfo.expactTimeStamp;
      this.getLeader();
    },
    //获取工程组长下拉框
    async getLeader() {
      try {
        let res = await getListLeader({ areaRoleType: 1 });
        //工程组长下拉框
        this.engineerList = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //点击确定新增
    handleSubmit() {
      this.$refs.dispatchForm.validate(valid => {
        if (valid) {
          this.addCheckPatchTaskReq();
        }
      });
    },
    //创建巡检调度任务请求
    async addCheckPatchTaskReq() {
      try {
        let res = await addCheckPatchTask({
          taskId: this.taskId,
          idList: this.idList,
          ...this.dispatchForm
        });
        if (res.code == "OK") {
          this.$message.success("创建成功");
          this.createDispatchDialog = false;
          this.getCheckAddressList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //关闭弹框
    handleClose(name) {
      this[name] = false;
      this.idList = [];
      this.itemList = [];
      this.$refs.elTable.clearSelection();
    },
    //查看已安装设备弹框关闭
    closeDialog() {
      this.showEquipVisible = false;
      this.createDispatchDialog = false;
      this.idList = [];
      this.$refs.elTable.clearSelection();
    }
  },
  created() {
    //获取巡检点
    this.getCheckAddressList();
    this.getActionItem();
    this.getData();
    this.initData();
  }
};
</script>
<style lang="scss" scoped>
/deep/.createDispatchDialog {
  .el-dialog__body {
    padding-top: 20px;
  }
  .el-input {
    width: 260px;
  }
  .city-div {
    width: 260px !important;
    .city-input {
      width: 260px !important;
    }
  }
}
</style>
